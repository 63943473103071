export default {
    getBillingInfo () {
        return window.api.get('/billing');
    },
    
    saveBillingInfo (data) {
        return window.api.post('/billing/create', data);
    },

    updateBillingInfo (data) {
        return window.api.put('/billing/update', data);
    }
}