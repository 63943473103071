<template>
    <Main>
        <sdPageHeader title="Attributes Maps" />
        <BehaviorComponent />
    </Main>
</template>
<script>
import { Main } from '../../styled';
import BehaviorComponent from './BehaviorComponent.vue';
export default {
    components: {
        Main,
        BehaviorComponent
    }
}
</script>