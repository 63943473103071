<template>
    <DatePickerWrapper>
        <a-range-picker 
          placeholder="Select range" 
          @change="onChangeRange" 
          v-model:value="range" 
      />
    </DatePickerWrapper>
</template>

<script>
import { DatePickerWrapper } from '../../../view/styled';
import { 
  defineComponent, 
  inject, 
  ref, 
  watch 
} from 'vue';
export default defineComponent({
  name: 'VisitsBehaviourTable',

  props: {
    timeline: String
  },

  components: {
    DatePickerWrapper
  },

  setup(props) {
    const range = ref(null);
    const emitEvent = inject('emitEvent');

    const onChangeRange = (date, dateString) => {
      if (dateString[0] === '' && dateString[1] === '') return;
      emitEvent('on-change-rage', dateString);
    }

    watch(() => props.timeline, (value) => {
      if (value) range.value = null;
    });

    return {
      range,
      onChangeRange
    }
  }
});
</script>