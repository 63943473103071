<template>
<div>
    <Main>
        <sdPageHeader title="Tops">
        </sdPageHeader>
        <MostInteractionProductsComponent />
    </Main>
</div>
</template>
<script>
import { Main } from '../../styled';
import MostInteractionProductsComponent from './MostInteractionProductsComponent.vue';
export default {
    components: {
        Main,
        MostInteractionProductsComponent
    }
}
</script>