<template>
    <sdCards title="Select range">
        <RangePicker style="width: 50%"/>
    </sdCards>
    <PerformanceChartWrapper>
        <sdCards title="Average revenue through recommendations">
            <Pstates>
                <div class="growth-upward">
                    <p>Revenue</p>
                    <sdHeading as="h1">
                        {{ helpers.numberFormatter(revenue) }}
                    </sdHeading>
                </div>
            </Pstates>

            <div v-if="isLoading" class="sd-spin">
                <a-spin />
            </div>

            <div v-else class="performance-lineChart">
                <sdChartContainer class="parentContainer">
                <Chart
                    class="performance"
                    id="average_revenue"
                    type="line"
                    :labels="labels"
                    :datasets="averageRevenueDatasets"
                    :options="chartOptions"
                    :height="height"
                />
                </sdChartContainer>
                <ul v-if="averageRevenueDatasets">
                    <li
                        v-for="(item, index) in averageRevenueDatasets"
                        :key="index + 1"
                        class="custom-label"
                    >
                        <span
                        :style="{
                            backgroundColor: item.borderColor,
                        }"
                        />
                        {{ item.label }}
                    </li>
                </ul>
            </div>
        </sdCards>
  </PerformanceChartWrapper>
</template>
<script>
import { 
    defineComponent, 
    ref, 
    onMounted, 
    computed, 
    toRefs, 
    provide 
} from 'vue';
import { PerformanceChartWrapper, Pstates } from '../styled_reports';
import { chartLinearGradient, customTooltips } from '../../../components/utilities/utilities';
import Chart from '../../../components/utilities/chartjs';
import { useStore } from 'vuex';
import moment from 'moment';
import VueTypes from 'vue-types';
import ReportsService from '../../../services/ReportsService';
import helpers from '../../../helpers/helpers';
import RangePicker from '../../../components/custom/date/RangePicker.vue';

export default defineComponent({
    name: 'SalesComponent',

    components: {
        PerformanceChartWrapper, 
        Pstates, 
        Chart,
        RangePicker
    },

    props: {
        chartHeights: VueTypes.number.def(100),
    },

    setup(props) {
        const { chartHeights } = toRefs(props);

        const picked = ref(moment(new Date));

        const { state, dispatch } = useStore();

        const isLoading = computed(() => state.actions.isLoading);

        const revenue = ref(0);

        const chartValues = ref(null);

        const labels = ref(null);

        const height = ref(window.innerWidth <= 575 ? 200 : chartHeights);

        onMounted(async () => {
            const start = moment(new Date).format('YYYY-MM-DD');
            const end = moment(new Date).add(30, 'd').format('YYYY-MM-DD');

            await getAverageRevenue(start, end);
        });

        const getAverageRevenue = async (start, date) => {
            dispatch("actions/setLoading", true);

            const response = (await ReportsService.getAverageRevenue(start, date)).data;

            revenue.value = Object.values(response?.revenue).reduce((acc, val) => acc + val);

            labels.value = Object.keys(response?.revenue);

            chartValues.value = Object.values(response?.revenue);

            dispatch("actions/setLoading", false);
            
        };

        const handleChangeRangeEvent = (range) => {
            if (range[0] === '' && range[1] === '') return;
            getAverageRevenue(range[0], range[1]);
        };

        provide('emitEvent', (event, data) => {
            handleChangeRangeEvent(data);
        });

        const averageRevenueDatasets = [
            {
                data: chartValues,
                borderColor: '#5F63F2',
                borderWidth: 4,
                fill: true,
                backgroundColor: () => chartLinearGradient(document.getElementById('average_revenue'), 300, {
                    start: '#5F63F230',
                    end: '#ffffff05',
                }),
                label: 'Current revenue',
                pointStyle: 'circle',
                pointRadius: '0',
                hoverRadius: '9',
                pointBorderColor: '#fff',
                pointBackgroundColor: '#5F63F2',
                hoverBorderWidth: 5,
            }
        ];

        const chartOptions = {
            maintainAspectRatio: true,
            elements: {
                z: 9999,
            },
            legend: {
                display: false,
            },
                hover: {
                mode: 'index',
                intersect: false,
            },
                tooltips: {
                mode: 'label',
                intersect: false,
                backgroundColor: '#ffffff',
                position: 'average',
                enabled: false,
                custom: customTooltips,
            },
            scales: {
                yAxes: [
                    {
                    gridLines: {
                        color: '#e5e9f2',
                        borderDash: [3, 3],
                        zeroLineColor: '#e5e9f2',
                        zeroLineWidth: 1,
                        zeroLineBorderDash: [3, 3],
                    },
                    ticks: {
                        beginAtZero: true,
                        fontSize: 13,
                        fontColor: '#182b49'
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: true,
                            zeroLineWidth: 2,
                            zeroLineColor: 'transparent',
                            color: 'transparent',
                            z: 1,
                            tickMarkLength: 0,
                        },
                        ticks: {
                            padding: 10,
                        },
                    },
                ],
            },
        };

        return {
            picked,
            moment,
            isLoading,
            revenue,
            chartValues,
            averageRevenueDatasets,
            chartOptions,
            labels,
            height,
            helpers
        }
    }
});
</script>