<template>
    <sdPageHeader title="Billing info" />
    <Main>
        <BillingInfoComponent />
    </Main>
</template>
<script>
import { Main } from '../styled';
import BillingInfoComponent from './BillingInfoComponent.vue';
export default {
    components: {
        Main,
        BillingInfoComponent
    }
}
</script>