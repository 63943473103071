<template>
  <PerformanceChartWrapper>
    <sdCards title="Average Usage">
      <template #button>
        <RangePicker />
      </template>
      <Pstates>
        <div class="growth-upward">
          <p>Usage</p>
          <sdHeading as="h1">
            {{ helpers.numberFormatter(usage) }}
          </sdHeading>
        </div>
      </Pstates>

      <div v-if="isLoading" class="sd-spin">
        <a-spin />
      </div>
      <div v-else class="performance-lineChart">
        <sdChartContainer class="parentContainer">
          <Chart
            class="performance"
            id="recommendation_usage"
            type="line"
            :labels="labels"
            :datasets="recommendationDatasets"
            :options="chartOptions"
            :height="height"
          />
        </sdChartContainer>
        <ul v-if="recommendationDatasets">
          <li
            v-for="(item, index) in recommendationDatasets"
            :key="index + 1"
            class="custom-label"
          >
            <span
              :style="{
                backgroundColor: item.borderColor,
              }"
            />
            {{ item.label }}
          </li>
        </ul>
      </div>
    </sdCards>
  </PerformanceChartWrapper>
</template>
<script>
import { PerformanceChartWrapper, Pstates } from '../styled_reports';
import { chartLinearGradient, customTooltips } from '../../../components/utilities/utilities';
import Chart from '../../../components/utilities/chartjs';
import { useStore } from 'vuex';
import { 
  computed, 
  onMounted, 
  ref, 
  toRefs, 
  defineComponent, 
  provide 
} from 'vue';
import ReportsService from '../../../services/ReportsService';
import VueTypes from 'vue-types';
import RangePicker from '../../../components/custom/date/RangePicker.vue';
import moment from 'moment';
import helpers from '../../../helpers/helpers';
  
export default defineComponent({
  name: 'UsageAndBudgetComponent',
  
  components: { 
    PerformanceChartWrapper, 
    Pstates, 
    Chart,
    RangePicker
  },

  props: {
    chartHeights: VueTypes.number.def(100),
  },

  setup(props) {
    const { chartHeights } = toRefs(props);
    const { state, dispatch } = useStore();

    const isLoading = computed(() => state.actions.isLoading);

    const usage = ref(0);

    const usageChart = ref([0, 0, 0, 0, 0, 0, 0]);

    const labels = ref(null);

    const type = ref('Week');

    const height = ref(window.innerWidth <= 575 ? 200 : chartHeights);
  
    onMounted(async () => {
      const start = moment(new Date).format('YYYY-MM-DD');
      const end = moment(new Date).add(30, 'd').format('YYYY-MM-DD');

      await getRecommendationUsage(start, end);
    });

    const getRecommendationUsage = async (start, end) => {
      dispatch("actions/setLoading", true);
      const response = (await ReportsService.getRecommendationUsage(start, end)).data;

      usage.value = helpers.sumOfObjectValues(response.usage.labels);

      labels.value = Object.keys(response.usage.labels);

      usageChart.value = Object.values(response.usage.labels);

      dispatch("actions/setLoading", false);
    }

    const handleChangeRangeEvent = (range) => {
      if (range[0] === '' && range[1] === '') return;
      getRecommendationUsage(range[0], range[1]);
    };

    provide('emitEvent', (event, data) => {
      handleChangeRangeEvent(data);
    });

    const recommendationDatasets = [
      {
        data: usageChart,
        borderColor: '#5F63F2',
        borderWidth: 4,
        fill: true,
        backgroundColor: () => chartLinearGradient(document.getElementById('recommendation_usage'), 300, {
          start: '#5F63F230',
          end: '#ffffff05',
        }),
        label: 'Usage',
        pointStyle: 'circle',
        pointRadius: '0',
        hoverRadius: '9',
        pointBorderColor: '#fff',
        pointBackgroundColor: '#5F63F2',
        hoverBorderWidth: 5,
      }
    ];

    const chartOptions = {
      maintainAspectRatio: true,
      elements: {
        z: 9999,
      },
      legend: {
        display: false,
      },
        hover: {
        mode: 'index',
        intersect: false,
      },
        tooltips: {
        mode: 'label',
        intersect: false,
        backgroundColor: '#ffffff',
        position: 'average',
        enabled: false,
        custom: customTooltips,
      },
      scales: {
        yAxes: [
          {
          gridLines: {
              color: '#e5e9f2',
              borderDash: [3, 3],
              zeroLineColor: '#e5e9f2',
              zeroLineWidth: 1,
              zeroLineBorderDash: [3, 3],
          },
          ticks: {
              beginAtZero: true,
              fontSize: 13,
              fontColor: '#182b49'
            },
          },
        ],
        xAxes: [
          {
          gridLines: {
            display: true,
            zeroLineWidth: 2,
            zeroLineColor: 'transparent',
            color: 'transparent',
            z: 1,
            tickMarkLength: 0,
          },
          ticks: {
                padding: 10,
            },
          },
        ],
      },
    };
    return {
      labels,
      usage, 
      labels,
      usageChart,
      recommendationDatasets,
      isLoading,
      performance,
      height,
      chartOptions,
      type,
      helpers
    };
  },
});
</script>
  