<template>
    <Main>
      <sdPageHeader title="Usage & Budget" />
      <a-row justify="center" :gutter="25">
        <a-col :xxl="16" :xs="24">
          <Suspense>
            <template #default>
              <UsageAndBudgetComponent />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton active />
              </sdCards>
            </template>
          </Suspense>
          <UpdateBudgetComponent />
        </a-col>
      </a-row>
    </Main>
</template>
  
<script>
import { Main } from '../../styled';
import UsageAndBudgetComponent from './UsageAndBudgetComponent.vue';
import UpdateBudgetComponent from './UpdateBudgetComponent.vue';

export default {
  components: {
    UsageAndBudgetComponent,
    UpdateBudgetComponent
  }
};
</script>
  