export default {
    getVisitorsBehavior(
        interval, 
        start, 
        end,
        timeRange
    ) {
        return window.api.get('/reports/visits_behaviour', {
            params: {
                interval: interval,
                start: start,
                end: end,
                timerange: timeRange
            }
        });
    },

    getRecommendationUsage(start, end) {
        return window.api.get('/reports/recommendation_usage', {
            params: {
                start: start, 
                end: end
            }
        });
    },

    getMostInteractionProducts(interval, type) {
        return window.api.get('/reports/most_interaction_products', {
            params: {
                interval: interval,
                type: type
            }
        });
    },

    getDailyReport(date) {
        return window.api.get('/reports/daily_report', {
            params: {
                date: date
            }
        });
    },

    getAttributesMapsReport() {
        return window.api.get('/reports/attribute_maps');
    },

    getAverageRevenue(start, end) {
        return window.api.get('/reports/average_revenue', {
            params: {
                start: start, 
                end: end
            }
        });
    }
}