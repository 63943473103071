<template>
  <sdCards title="Select date">
    <DatePickerWrapper>
      <a-date-picker :default-value="moment(new Date())" @change="onDateChange" v-model:value="picked" />
    </DatePickerWrapper>
  </sdCards>
  <sdCards title="The most recommended products">
    <LadingPages>
      <div class="table-bordered top-seller-table table-responsive" v-if="isLoading === false">
        <a-table :columns="theMostWantedProductsColumns" :dataSource="dataSourceMostWantedProducts" :pagination="false" />
      </div>
    </LadingPages>
  </sdCards>

  <sdCards title="The most liked products">
    <TrafficTableWrapper>
      <div class="table-bordered top-seller-table table-responsive">
        <a-table :columns="theMostLikedProductsColumns" :dataSource="dataSourceMostLikedProducts" :pagination="false" />
      </div>
    </TrafficTableWrapper>
  </sdCards>

  <sdCards title="Products of low interest">
    <TrafficTableWrapper>
      <div class="table-bordered top-seller-table table-responsive">
        <a-table :columns="productsOfLowInterestColumns" :dataSource="dataSourceProductsOfLowInterest" :pagination="false" />
      </div>
    </TrafficTableWrapper>
  </sdCards>

  <sdCards title="Top buying intention">
    <TrafficTableWrapper>
      <div class="table-bordered top-seller-table table-responsive">
        <a-table :columns="topBuyingInterestColumns" :dataSource="dataSourcetopBuyingInterest" :pagination="false" />
      </div>
    </TrafficTableWrapper>
  </sdCards>
</template>
<script>
import { DatePickerWrapper } from '../../styled';
import { defineComponent, onMounted, computed, ref } from 'vue';
import { LadingPages, TrafficTableWrapper } from '../styled_reports';
import ReportsService from '../../../services/ReportsService';
import Datepicker from 'vue3-datepicker';
import helpers from '../../../helpers/helpers';
import moment from 'moment';

export default defineComponent({
  props: {
    columns: Array,
    title: String,
    type: String
  },
  name: 'MostInteractionProductsComponent',
  components: {
    LadingPages,
    Datepicker,
    TrafficTableWrapper,
    DatePickerWrapper
  },

  setup() {
    const theMostWantedProductsColumns = [
      {
        title: 'Products',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Total recommendations',
        dataIndex: 'total_recommendations',
        key: 'total_recommendations',
      },
      {
        title: 'Action rate',
        dataIndex: 'action_rate',
        key: 'action_rate',
      }
    ];

    const theMostLikedProductsColumns = [
      {
        title: 'Products',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Like percentage',
        dataIndex: 'percentage',
        key: 'percentage',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      }
    ];

    const productsOfLowInterestColumns = [
      {
        title: 'Products',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Dislike percentage',
        dataIndex: 'percentage',
        key: 'percentage',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      }
    ];

    const topBuyingInterestColumns = [
      {
        title: 'Products',
        dataIndex: 'products',
        key: 'products',
      },
      {
        title: 'Buying intention percentage',
        dataIndex: 'percentage',
        key: 'percentage',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      }
    ];
    
    const picked = ref(moment(new Date));
    const theMostWantedProducts = ref([]);
    const theMostLikedProducts = ref([]);
    const productsOfLowInterest = ref([]);
    const topBuyingInterest = ref([]);

    onMounted(async () => {
      await getDailyReport();
    });

    const getDailyReport = async (date = moment(new Date()).format('YYYY-MM-DD')) => {
        const response = (await ReportsService.getDailyReport(date)).data;

        theMostWantedProducts.value = response?.the_most_recommended_products;
        theMostLikedProducts.value = response?.the_most_liked_products;
        productsOfLowInterest.value = response?.products_of_low_interest;
        topBuyingInterest.value = response?.top_buying_interest;
    }

    const onDateChange = async () => {
      theMostWantedProducts.value = [];
      theMostLikedProducts.value = [];
      productsOfLowInterest.value = [];
      topBuyingInterest.value = [];
      getDailyReport(picked.value.format('YYYY-MM-DD'));
    }

    const dataSourceMostWantedProducts = computed(() => {
      return theMostWantedProducts.value.map((data, key) => {
        return {
          key: key + 1,
          products: (<a href={data.target} style="color: #5F63F2 !important" class="page-title" target="_blank">
                {data.product_name}
              </a>),
          total_recommendations: data._count,
          action_rate: `${data.percentage ? data.percentage.toFixed(2) : 0} %`
        }
      })
    });

    const dataSourceMostLikedProducts = computed(() => {
      return theMostLikedProducts.value.map((data, key) => {
        return {
          key: key + 1,
          products: (<a href={data.taget} target="_blank" style="color: #5F63F2 !important;">{data.product_name}</a>),
          percentage: (
            <a-progress
              percent={data.percentage}
              strokeWidth={5}
              status="active"
              showInfo={false}
              class={helpers.getColorByPercentage(data.percentage ? data.percentage.toFixed(2) : 0)}
            />
          ),
          value: `${data.percentage ? data.percentage.toFixed(2) : 0} %`
        }
      });
    });

    const dataSourceProductsOfLowInterest = computed(() => {
      return productsOfLowInterest.value.map((data, key) => {
        return {
          key: key + 1,
          products: (<a href={data.target} target="_blank" style="color: #5F63F2 !important;">{data.product_name}</a>),
          percentage: (
            <a-progress
              percent={data.percentage}
              strokeWidth={5}
              status="active"
              showInfo={false}
              class={helpers.getColorByPercentage(data.percentage ? data.percentage.toFixed(2) : 0)}
            />
          ),
          value: `${data.percentage ? data.percentage.toFixed(2) : 0 } %`
        }
      });
    });

    const dataSourcetopBuyingInterest = computed(() => {
      return topBuyingInterest.value.map((data, key) => {
        return {
          key: key + 1,
          products: (<a href={data.target} target="_blank" style="color: #5F63F2 !important;">{data.product_name}</a>),
          percentage: (
            <a-progress
              percent={data.percentage}
              strokeWidth={5}
              status="active"
              showInfo={false}
              class={helpers.getColorByPercentage(data.percentage ? data.percentage.toFixed(2) : 0)}
            />
          ),
          value: `${data.percentage ? data.percentage.toFixed(2) : 0 } %`
        }
      });
    });

    return {
      picked,
      isLoading, 
      dataSourceMostWantedProducts,
      dataSourceMostLikedProducts,
      dataSourceProductsOfLowInterest,
      dataSourcetopBuyingInterest,
      theMostWantedProductsColumns,
      theMostLikedProductsColumns,
      productsOfLowInterestColumns,
      topBuyingInterestColumns,
      onDateChange,
      moment
    }
  }
});
</script>