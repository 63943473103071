<template>
    <Main>
        <sdPageHeader title="Sales" />
        <SalesComponent />
    </Main>
</template>
<script>
import { Main } from '../../styled';
import SalesComponent from './SalesComponent.vue';
export default {
    components: {
        SalesComponent,
        Main
    }
}
</script>