<template>
<a-row :gutter="15">
    <a-col :xs="24">
        <RecordFormWrapper>
            <sdCards headless>
                <a-row justify="center">
                    <a-col :xl="10" :md="16" :xs="24">
                        <BasicFormWrapper>
                            <a-form
                                class="add-record-form"
                                :style="{ width: '100%' }"
                                layout="vertical"
                            >

                                <a-form-item name="company_name" label="Company name">
                                    <a-input 
                                    v-model:value="companyName" 
                                    placeholder="Input Company Name" />
                                    <span class="error-messages" v-if="v$.companyName.$error">
                                        {{ v$.companyName.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_vat" label="Company VAT">
                                    <a-input 
                                    v-model:value="companyVat" 
                                    placeholder="Input Company VAT" />
                                    <span class="error-messages" v-if="v$.companyVat.$error">
                                        {{ v$.companyVat.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_address" label="Company address">
                                    <a-input 
                                    v-model:value="companyAddress" 
                                    placeholder="Input Company Address" />
                                    <span class="error-messages" v-if="v$.companyAddress.$error">
                                        {{ v$.companyAddress.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_email" label="Company email">
                                    <a-input 
                                    v-model:value="companyEmail" 
                                    placeholder="Input Company Email" />
                                    <span class="error-messages" v-if="v$.companyEmail.$error">
                                        {{ v$.companyEmail.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_city" label="Company City">
                                    <a-input 
                                    v-model:value="companyCity" 
                                    placeholder="Input Company City" />
                                    <span class="error-messages" v-if="v$.companyCity.$error">
                                        {{ v$.companyCity.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_country" label="Company country">
                                    <a-input 
                                    v-model:value="companyCountry" 
                                    placeholder="Input Company Country" />
                                    <span class="error-messages" v-if="v$.companyCountry.$error">
                                        {{ v$.companyCountry.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="company_zip_code" label="Company ZIP code">
                                    <a-input 
                                    v-model:value="companyZipCode" 
                                    placeholder="Input Company ZIP Code" />
                                    <span class="error-messages" v-if="v$.companyZipCode.$error">
                                        {{ v$.companyZipCode.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <a-form-item name="package" label="Package">
                                    <SelectWrapperStyle>
                                        <a-select 
                                            v-model:value="stripePackage"
                                            style="width: 520px" 
                                            @change="handleChange"
                                        >
                                            <a-select-option 
                                                v-for="(product, key) in products" :key="product.id">
                                                {{ product.name }}
                                            </a-select-option>
                                        </a-select>
                                    </SelectWrapperStyle>
                                    <span class="error-messages" v-if="v$.stripePackage.$error">
                                        {{ v$.stripePackage.$errors[0].$message }}
                                    </span>
                                </a-form-item>

                                <div class="record-form-actions text-right">
                                <sdButton 
                                    size="default" 
                                    htmlType="Save" 
                                    type="primary"
                                    @click="saveBillingInfo"
                                >
                                    {{ 'Submit' }}
                                </sdButton>
                                </div>
                            </a-form>
                        </BasicFormWrapper>
                    </a-col>
                </a-row>
            </sdCards>
        </RecordFormWrapper>
    </a-col>
</a-row>
</template>
<script>
import { RecordFormWrapper } from './style';
import { BasicFormWrapper, SelectWrapperStyle } from '../styled';
import { computed, ref, defineComponent, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';
import BillingService from '../../services/BillingService';
  
export default defineComponent({
    name: 'BillingInfoComponent',
    
    components: { 
        RecordFormWrapper, 
        BasicFormWrapper ,
        SelectWrapperStyle
    },

    setup() {
        const companyName = ref(null);
        const companyAddress = ref (null);
        const companyVat = ref(null);
        const companyEmail = ref(null);
        const companyCity = ref(null);
        const companyCountry = ref(null);
        const companyZipCode = ref(null);
        const stripePackage = ref(null);
        const products = ref([]);

        let billingExist = ref(false);

        onMounted(async () => {
            await getBillingInfo();
        });

        const rules = computed(() => ({
            companyName: {
                required: helpers.withMessage(
                    "The company field is required",
                    required
                )
            },

            companyAddress: {
                required: helpers.withMessage(
                    "The company address field is required",
                    required
                )
            },

            companyVat: {
                required: helpers.withMessage(
                    "The company vat field is required",
                    required
                )
            },

            companyEmail: {
                required: helpers.withMessage(
                    "The company email field is required",
                    required
                ),

                email: helpers.withMessage(
                    "The field email is not valid", 
                    email
                )
            },

            companyCity: {
                required: helpers.withMessage(
                    "The company city field is required",
                    required
                ),
            },

            companyCountry: {
                required: helpers.withMessage(
                    "The company country field is required",
                    required
                ),
            },

            companyZipCode: {
                required: helpers.withMessage(
                    "The company zip code field is required",
                    required
                )
            },
            
            stripePackage: {
                required: helpers.withMessage(
                    "The package field is required",
                    required
                )
            }
        }));

        const getBillingInfo = async () => {
            const response = (await BillingService.getBillingInfo()).data;

            console.log(response);

            if (response.billing !== null) billingExist.value = true;

            companyName.value = response.billing?.company_name;
            companyAddress.value = response.billing?.company_address ?? "";
            companyCity.value = response.billing?.city ?? "";
            companyEmail.value = response.billing?.company_email ?? "";
            companyVat.value = response.billing?.company_vat ?? "";
            companyZipCode.value = response.billing?.zip_code ?? "";
            companyCountry.value = response.billing?.country ?? "";

            response.packages.forEach((stripeProduct) => {
                products.value.push({
                    id: stripeProduct.stripe_id,
                    name: `${stripeProduct.name}`
                });
                stripePackage.value = stripeProduct.name;
            });

            if (response.billing?.package) {
                products.value.forEach((product) => {
                    if (product.id === response.billing.package) {
                        stripePackage.value = product.name;
                    }
                });
            }
        }

        const handleChange = (value) => stripePackage.value = value;

        const saveBillingInfo = async () => {
            if (! await v$._value.$validate()) return;

            const data = {
                company_name: companyName.value,
                company_address: companyAddress.value,
                company_email: companyEmail.value,
                city: companyCity.value,
                country: companyCountry.value,
                zip_code: companyZipCode.value,
                company_vat: companyVat.value,
                package: stripePackage.value
            }

            billingExist.value === false ? await BillingService.saveBillingInfo(data) : await BillingService.updateBillingInfo(data);

            getBillingInfo();

            await window.showAlert(
                "success",
                "Success",
                "Billing info saved successfully!"
            );
        }

        const v$ = useVuelidate(rules, { 
                companyAddress,
                companyCountry,
                companyCity,
                companyVat,
                companyZipCode,
                companyName,
                companyEmail,
                stripePackage
            }
        );
      
        return {
            companyAddress,
            companyCountry,
            companyCity,
            companyVat,
            companyZipCode,
            companyName,
            companyEmail,
            products,
            stripePackage,
            handleChange,
            v$,
            saveBillingInfo
        };
    },
  });

</script>
  