<template>
    <a-row justify="center" :gutter="25">
        <a-col :md="12" :sm="24" :xs="24">
            <sdCards title="Your attributes map">
                <div v-if="isLoading" class="sd-spin">
                    <a-spin />
                </div>

                <div id="chart" v-else>
                    <apexchart 
                        type="radar" 
                        height="350" 
                        :options="attributesMapsCountChartOptions" 
                        :series="attributesMapsCount">
                    </apexchart>
                </div>
            </sdCards>
        </a-col> 

        <a-col :md="12" :sm="24" :xs="24">
            <sdCards title="Users attributes maps">
                <div v-if="isLoading" class="sd-spin">
                    <a-spin />
                </div>

                <div id="chart" v-else>
                    <apexchart 
                        type="radar" 
                        height="350" 
                        :options="attributesViewedByUsersChartOptions" 
                        :series="attributesViewedByUsers">
                    </apexchart>
                </div>
            </sdCards>
        </a-col> 
    </a-row>
</template>
<script>
import { defineComponent, onMounted, ref, computed} from 'vue';
import { useStore } from 'vuex';
import ReportsService from '../../../services/ReportsService';

export default defineComponent({
    name: 'BehaviorComponent',

    setup () {
        const attributesMapsCount = [
            {
                name: 'Count',
                data: ref([])
            }
        ];

        const attributesViewedByUsers = [
            {
                name: 'Count',
                data: ref([])
            }
        ];

        let attributesMapsCountLabels = ref([]);

        let attributesViewedByUsersLabels = ref([]);

        const { dispatch, state } = useStore();

        const isLoading = computed(() => state.actions.isLoading);

        onMounted(async () => {
            getAttributesMapsReport();
        });

        const getAttributesMapsReport = async () => {
            dispatch("actions/setLoading", true);

            const response = (await ReportsService.getAttributesMapsReport()).data;

            attributesMapsCountLabels.value = Object.keys(response.attributesMapsCount);
            attributesViewedByUsersLabels.value = Object.keys(response.attributesViewedByUsers);

            attributesMapsCount[0].data.value = Object.values(response.attributesMapsCount);
            attributesViewedByUsers[0].data.value = Object.values(response.attributesViewedByUsers);

            dispatch("actions/setLoading", false);
        };

        const attributesMapsCountChartOptions = computed(() => ({
            chart: {
                height: 350,
                type: 'radar',
            },
            dataLabels: {
                enabled: true,
            },
            plotOptions: {
                radar: {
                    size: 140,
                        polygons: {
                            strokeColors: '#e9e9e9',
                            fill: {
                                colors: ['#f8f8f8', '#fff'],
                            },
                        },
                    },
            },
            xaxis: {
                categories: attributesMapsCountLabels.value
            },
            yaxis: {
                tickAmount: 7,
                labels: {
                    formatter: (val, i) => {
                        if (i % 2 === 0) {
                            return val;
                        } else {
                            return '';
                        }
                    },
                },
            },
        }));

        const attributesViewedByUsersChartOptions = computed(() => ({
            chart: {
                height: 350,
                type: 'radar',
            },
            xaxis: {
                categories: attributesViewedByUsersLabels.value
            },
            dataLabels: {
                enabled: true,
            },
            plotOptions: {
                radar: {
                    size: 140,
                        polygons: {
                            strokeColors: '#e9e9e9',
                            fill: {
                                colors: ['#f8f8f8', '#fff'],
                            },
                        },
                    },
            },
            colors: ['#FF4560'],
            markers: {
                size: 4,
                colors: ['#fff'],
                strokeColor: '#FF4560',
                strokeWidth: 2,
            },
            tooltip: {
            y: {
                    formatter: (val) => {
                        return val;
                    },
                },
            },
            yaxis: {
                tickAmount: 7,
                labels: {
                    formatter: (val, i) => {
                        if (i % 2 === 0) {
                            return val;
                        } else {
                            return '';
                        }
                    },
                },
            },
        }));


        return {
            attributesMapsCountChartOptions,
            attributesMapsCount,
            attributesViewedByUsers,
            attributesViewedByUsersChartOptions,
            isLoading
        }
    }
});
</script>