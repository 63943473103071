export default {
    getColorByPercentage (percentage) {
        if (percentage >= 0 && percentage < 50) return 'progress-ot progress-danger';
        if (percentage > 50 && percentage < 70) return 'progress-smt progress-warning';
        if (percentage > 70) return 'progress-dt progress-primary';
    },

    numberFormatter(num) {
        if (num < 999) {
            return num.toFixed(2);
        } else if(num > 999 && num < 1000000){
            return (num / 1000).toFixed(1) + 'K'; // convert to K for number from > 1000 < 1 million 
        } else if(num > 1000000){
            return (num / 1000000).toFixed(1) + 'M'; // convert to M for number from > 1 million 
        } else if(num < 900){
            return num; // if value < 1000, nothing to do
        }
    },

    sumOfObjectValues(obj) {
        return Object.values(obj).reduce((acc, val) => acc + val);
    }
}