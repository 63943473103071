<template>
  <BasicFormWrapper class="mb-25">
    <sdCards title="Budget settings">
      <a-form name="sDash_sized-form" layout="vertical">
        <a-row :gutter="30">
          <a-col :xs="24">
            <a-form-item class="large-input" label="Budget">
              <a-input 
              size="large" 
              placeholder="Budget e.g 2500" 
              :value="budget"
              v-model:value="budget"
            >
            </a-input>
            <span class="error-messages" v-if="v$.budget.$error">
						  {{ v$.budget.$errors[0].$message }}
					  </span>
            </a-form-item>
            <sdButton class="btn-save" type="primary" @click="updateBudget"> Save </sdButton>
          </a-col>
        </a-row>
      </a-form>
    </sdCards>
  </BasicFormWrapper>
</template>
<script>
import { defineComponent, ref, onMounted, computed } from 'vue';
import { BasicFormWrapper } from "../../styled";
import BudgetService from '../../../services/BudgetService';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, numeric } from '@vuelidate/validators'

export default defineComponent({
  name: 'UpdateBudgetComponent',

  setup() {
    const budget = ref(0);

    onMounted(async () => {
      await getBudget();
    });

    const rules = computed(() => ({
      budget: {
        required: helpers.withMessage(
          "The budget is required",
          required
				),
        numeric: helpers.withMessage(
          "The budget must be a number",
          numeric
        )
      },
    }));

    const v$ = useVuelidate(rules, { budget });

    const getBudget = async () => {
      const response = (await BudgetService.getBudget()).data;

      budget.value = response.budget.recommendation_budget;
    }

    const updateBudget = async () => {
      if (! await v$._value.$validate()) return;

      const data = {
        recommendation_budget: parseFloat(budget.value)
      };

      await BudgetService.updateBudget(data);
      await getBudget();

      await window.showAlert(
				"success",
				"Success",
				"Budget updated successfully!"
			);
    }


    return {
      budget,
      v$,
      updateBudget
    }
  }
});
</script>