<template>
  <Main>
    <sdPageHeader title="Visitors behaviour" />
    <a-row justify="center" :gutter="25">
      <a-col :xxl="16" :xs="24">
        <Suspense>
          <template #default>
            <VisitsBehaviourComponent />
          </template>
          <template #fallback>
            <sdCards headless>
              <a-skeleton active />
            </sdCards>
          </template>
        </Suspense>
      </a-col>
    </a-row>
  </Main>
</template>
  
<script>
import { Main } from '../../styled';
import { defineAsyncComponent } from 'vue';
const VisitsBehaviourComponent = defineAsyncComponent(() => import('./VisitsBehaviourComponent.vue'));
  
export default {
  components: {
    Main,
    VisitsBehaviourComponent
  }
};
</script>
  